import { StatusCodes } from "http-status-codes";
import { MicrobialResiduesType } from "./types/microbial";

export enum ProtocolType {
  Validation = "validation",
  Verification = "verification",
  PeriodicVerification = "periodicVerification",
  NewProductIntroduction = "newProductIntroduction",
  ProductChangeover = "productChangeover",
  BatchChangeover = "batchChangeover",
  OnDemandStudy = "onDemandStudy"
}
export enum ProtocolTypeVisual {
  validation = "Validation",
  verification = "Verification",
  periodicVerification = "Periodic Verification",
  newProductIntroduction = "New Product Introduction",
  productChangeover = "Product Changeover",
  batchChangeover = "Batch Changeover",
  onDemandStudy = "Study"
}

export enum MimeExtensionMap {
  "text/plain" = "txt",
  "application/msword" = "doc",
  "application/pdf" = "pdf",
  "application/vnd.ms-excel" = "xls",
  "application/vnd.ms-powerpoint" = "ppt",
  "application/x-7z-compressed" = "7z",
  "application/x-rar-compressed" = "rar",
  "application/zip" = "zip",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" = "docx",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" = "xlsx"
}

export enum FixRunErrorType {
  Command = "Command",
  Batch = "Batch"
}

export enum MeaningOfSignature {
  Submit = "Submit",
  Approve = "Approve",
  Review = "Review",
  Reject = "Reject"
}

export enum AnalyticalMethodType {
  Product = "product",
  Intermediate = "intermediate",
  CleaningAgent = "cleaningAgent",
  BioBurden = "BioBurden",
  Endotoxin = "Endotoxin",
  Nitrosamine = "Nitrosamine"
}

export enum AnalyticalMethodTypeVisual {
  intermediate = "Intermediate",
  product = "API",
  cleaningAgent = "Cleaning Agent",
  BioBurden = "Bioburden",
  Endotoxin = "Endotoxin",
  Nitrosamine = "Nitrosamine"
}

export enum MeaningOfSignatureLabelType {
  Approve = "Approved",
  Review = "Reviewed",
  Submit = "Submitted",
  RejectOnSubmit = "Rejected",
  RejectOnReview = "Review Rejected",
  RejectOnApprove = "Approval Rejected"
}

export const MeaningOfSignaturesLabel = {
  [MeaningOfSignature.Approve]: "Approve",
  [MeaningOfSignature.Reject]: "Reject Assessment",
  [MeaningOfSignature.Review]: "Review",
  [MeaningOfSignature.Submit]: "Submit"
};

export const EmailMeaningOfSignatureLabel = {
  [MeaningOfSignature.Review]: "Review",
  [MeaningOfSignature.Approve]: "Approval"
};

export enum ResidueTypes {
  Product = "product",
  CleaningAgent = "cleaningAgent"
}

export type AllResidueTypes = ResidueTypes | MicrobialResiduesType | AdditionalTests;

export enum UserPlusOperatorType {
  User = "user",
  Operator = "operator"
}

export enum ValidationResidueCheck {
  Worst = "worst",
  Recent = "recent"
}

export enum ReportDocumentType {
  MACProtocol = "MACProtocol",
  CleaningValidationSOP = "CleaningValidationSOP",
  CleaningProcedure = "CleaningProcedure",
  AnalyticalMethod = "AnalyticalMethods",
  FailureHandlingSOP = "FailureHandlingSOP",
  CEReport = "CEReport",
  MACCalculationMatrix = "MACCalculationMatrix",
  SamplingSheet = "SamplingSheet",
  AnnualSummaryReport = "AnnualSummaryReport"
}

export enum DocumentName {
  MACProtocol = "Limit and Worst Case Report",
  CEReport = "Change Assessment Report",
  MACCalculationMatrix = "MAC Matrix",
  AnnualSummaryReport = "Annual Summary Report",
  MACProtocolAPI = "Limit Report"
}

export enum LegacyDocumentType {
  LWC = "LWC",
  Assessments = "Assessments",
  Protocols = "Protocols",
  SummaryReports = "Summary Reports",
  AuditLogs = "Audit Logs",
  ReviewReports = "Review Reports"
}

export enum AssessmentState {
  NotEvaluated = "Not Evaluated",
  PendingApproval = "Pending Approval",
  Approved = "Approved",
  Cancelled = "Cancelled"
}

export enum CollaboratorState {
  Signed = "Signed",
  PendingApproval = "PendingApproval",
  ChangesRequested = "ChangesRequested"
}

export enum CollaboratorStateLabel {
  Signed = "Signed",
  PendingApproval = "Signing Pending",
  ChangesRequested = "Changes Requested"
}

export enum CollaboratorStateColor {
  Signed = "#52C41A",
  PendingApproval = "#D9D9D9",
  ChangesRequested = "#FAAD14"
}

export enum ApprovalAction {
  Approve = "approve",
  Modify = "modify"
}

export enum CollaboratorType {
  Submitter = "Submitter",
  Reviewer = "Reviewer",
  Approver = "Approver"
}

export enum ElectronicRecordType {
  Protocol = "Protocol",
  Study = "Study",
  Inspection = "Inspection",
  OfflineStudy = "Offline Study"
}

export enum SamplingMethodType {
  Swab = "swab",
  Rinse = "rinse"
}

export enum SamplingLocationsContactType {
  Direct = "Direct",
  Indirect = "Indirect"
}

export enum Operator {
  And = "and",
  Or = "or"
}

export enum ProductTier {
  Full = "full",
  Lite = "lite"
}

export enum HttpStatus {
  // [rfc7231] The 400 (Bad Request) status code indicates that the server cannot or
  // will not process the request due to something that is perceived to be
  // a client error (e.g., malformed request syntax, invalid request
  // message framing, or deceptive request routing).
  BadRequest = StatusCodes.BAD_REQUEST, // malformed request syntax, size too large, invalid request message framing, or deceptive request routing
  Unauthenticated = StatusCodes.UNAUTHORIZED, // authentication is required and has failed or has not yet been provided
  Forbidden = StatusCodes.FORBIDDEN, // Authenticated but not allowed
  // [rfc7231] server did not find a current representation for the target resource or is not willing to disclose that one exists.
  NotFound = StatusCodes.NOT_FOUND,

  // The request could not be completed due to a conflict with the current state of the resource.
  // This code is only allowed in situations where it is expected that the user might be able to resolve the conflict and resubmit the request.
  // The response body SHOULD include enough information for the user to recognize the source of the conflict.
  Conflict = StatusCodes.CONFLICT,

  // NOTE: 422 has been removed as of rfc7231
  // The 422 (Unprocessable Entity) status code means the server
  // understands the content type of the request entity
  // (hence a 415(Unsupported Media Type) status code is inappropriate),
  // and the syntax of the request entity is correct (thus a 400 (Bad Request) status code is inappropriate)
  // but was unable to process the contained instructions.
  UnprocessableEntity = StatusCodes.UNPROCESSABLE_ENTITY,
  InternalServerError = StatusCodes.INTERNAL_SERVER_ERROR,
  OK = StatusCodes.OK,
  PreconditionFailed = StatusCodes.PRECONDITION_FAILED,
  SeeOther = StatusCodes.SEE_OTHER,
  SimultaneousSession = StatusCodes.INSUFFICIENT_SPACE_ON_RESOURCE
}

export enum ValidationStates {
  Validated = "Validated",
  OnGoing = "On Going",
  Failed = "Failed"
}

export enum RoomGradeNames {
  A = "Grade A",
  B = "Grade B",
  C = "Grade C",
  D = "Grade D"
}

export enum ClientErrors {
  TransactionLock = "TransactionLocked",
  NotAuthorized = "Not allowed"
}

export enum PasswordPolicyRules {
  minNumber = "One number",
  minCapital = "One uppercase character",
  specialCharacters = "One special character"
}

export enum PasswordPolicyConfiguration {
  MIN_NUMBER = "minNumber",
  MIN_CAPITAL = "minCapital",
  SPECIAL_CHAR = "specialCharacters",
  MIN_PASSWORD_LENGTH = "minPasswordLength",
  PASSWORD_MATCH = "passwordsMatch"
}

export enum AdditionalTests {
  TOC = "TOC",
  pH = "pH",
  Conductivity = "Conductivity",
  AA = "AA",
  Odour = "Odour",
  RC = "RC"
}

export enum FileType {
  XLSX = "xlsx",
  PDF = "pdf"
}

export enum UserProvider {
  LOCAL = "local",
  ACTIVEDIRECTORY = "ad",
  SSO = "sso"
}

export enum Comparator {
  LessThanEqualTo = "LessThanEqualTo",
  LessThan = "LessThan",
  GreaterThanEqualTo = "GreaterThanEqualTo",
  GreaterThan = "GreaterThan",
  EqualTo = "EqualTo"
}

export enum TargetType {
  Equipment = "Equipment",
  EquipmentGroup = "Equipment Group"
}

export enum SSOVerificationHandler {
  ChangeAssessment = "changeAssessment",
  ValidationStudies = "validationStudies",
  RunApproval = "runApproval",
  ErSigning = "erSigning",
  ErSubmit = "erSubmit"
}

export enum SessionStorageKeys {
  CURRENT_FACILITY = "current_facility",
  PRODUCT_TYPE = "product_type",
  FACILITY_TIER = "tier",
  FEATURE_FLAGS = "featureFlags",
  EMAIL = "email",
  USER_ID = "userId",
  ADDITIONAL_API_PROPERTIES = "apiAdditionalFields",
  ADDITIONAL_INTERMEDIATE_PROPERTIES = "intermediateAdditionalFields",
  ADDITIONAL_PRODUCT_PROPERTIES = "productAdditionalFields",
  ADDITIONAL_PRODUCTION_PROPERTIES = "productionAdditionalFields",
  NON_GENUINE_LICENSE_INFORMATION = "licenseInformation",
  TOKEN = "token"
}

export enum CleaningLimitPolicyResidueType {
  Chemical = "Chemical",
  Detergent = "Detergent",
  Intermediate = "Intermediate",
  Nitrosamine = "Nitrosamine"
}

export enum MassUnits {
  mg = "mg",
  ug = "ug"
}

export enum ConcentrationLimitUnits {
  mgPerMl = "mg/ml",
  ppm = "ppm",
  ugPerMl = "ug/ml"
}

export enum LicenseState {
  INTIMATE = "intimate",
  GRACE = "grace",
  GRACE_EXCEEDED = "grace-exceeded",
  GENUINE = "genuine"
}

export enum OnDemandProtocolState {
  BeingBuilt = "BeingBuilt",
  BeingReviewed = "BeingReviewed",
  BeingApproved = "BeingApproved",
  Approved = "Approved",
  Deprecated = "Deprecated"
}

export enum OnDemandStateColor {
  BeingBuilt = "#D9D9D9",
  BeingReviewed = "#1890FF",
  BeingApproved = "#FAAD14",
  Approved = "#52C41A",
  Deprecated = "#D9D9D9",
  InProgress = "#FAAD14",
  Completed = "#52C41A",
  NotStarted = "#D9D9D9"
}

export enum OnDemandProtocolStateLabel {
  BeingBuilt = "Being Built",
  BeingReviewed = "Being Reviewed",
  BeingApproved = "Being Approved",
  Approved = "Active",
  Deprecated = "Deprecated"
}

export enum OnDemandStudyState {
  InProgress = "InProgress",
  BeingReviewed = "BeingReviewed",
  BeingApproved = "BeingApproved",
  Completed = "Completed"
}

export enum OnDemandStudyStateLabel {
  InProgress = "In Progress",
  BeingReviewed = "Being Reviewed",
  BeingApproved = "Being Approved",
  Completed = "Completed"
}

export enum OnDemandInspectionStateLabel {
  InProgress = "In Progress",
  BeingApproved = "Being Approved",
  BeingReviewed = "Being Reviewed",
  Completed = "Completed",
  NotStarted = "Not Started"
}

export enum OnDemandInspectionState {
  InProgress = "InProgress",
  BeingApproved = "BeingApproved",
  BeingReviewed = "BeingReviewed",
  Completed = "Completed",
  NotStarted = "NotStarted"
}

export enum InspectionStage {
  BatchDetails = "batchDetails",
  CleaningDetails = "cleaningDetails",
  Visual = "visual",
  RinseClarity = "rinseClarity",
  Actives = "actives",
  Intermediates = "intermediates",
  Bioburden = "bioburden",
  CleaningAgent = "cleaningAgent",
  Microbial = "microbial",
  Endotoxin = "endotoxin",
  RC = "rinseClarity",
  Odour = "odour",
  TOC = "TOC",
  AA = "atomicAdsorption",
  pH = "pH",
  Conductivity = "conductivity",
  Nitrosamines = "nitrosamines",
  Bacterial = "bacterial",
  Fungal = "fungal"
}

export enum InspectionReportSubsectionTitle {
  actives = "Chemical",
  intermediates = "Intermediate",
  cleaningAgent = "Cleaning Agent",
  nitrosamines = "Nitrosamine",
  microbial = "Microbial",
  bioburden = "Bioburden",
  endotoxin = "Endotoxin",
  atomicAdsorption = "Atomic Adsorption",
  conductivity = "Conductivity",
  pH = "pH",
  TOC = "TOC",
  bacterial = "Bacterial",
  fungal = "Fungal"
}

export enum InspectionStageTitle {
  batchDetails = "Batch Details",
  cleaningDetails = "Cleaning Details",
  visual = "Visual Inspection",
  actives = "Active Residue Verification",
  intermediates = "Intermediate Residue Verification",
  bioburden = "Bioburden Residue Verification",
  cleaningAgent = "Cleaning Agent Residue Verification",
  microbial = "Microbial Residue Verification",
  endotoxin = "Endotoxin Residue Verification",
  rinseClarity = "Rinse Clarity Verification",
  odour = "Odour Verification",
  TOC = "TOC",
  atomicAdsorption = "Atomic Absorption",
  pH = "pH",
  conductivity = "Conductivity",
  nitrosamines = "Nitrosamine Residue Verification",
  additional = "Additional Test Verification",
  bacterial = "Bacterial Residue Verification",
  fungal = "Fungal Residue Verification"
}

export enum InspectionStageDescription {
  batchDetails = "Users will be asked to record Batch Details here such as Batch Number and when manufacturing was completed.",
  cleaningDetails = "Users will be asked to record Cleaning Details here such as people involved in Cleaning and when Cleaning was completed.",
  visual = "Users will be asked to record Visual Inspection Details here such as people involved in Visual Inspection and when Visual Inspection was completed.",
  actives = "Users will be asked to record Sampling and Residue Details for Actives here, such as People involved in Sample collection, analysis & review, when were the sampling activities completed, and residue obtained at different Sampling Locations.",
  intermediates = "Users will be asked to record Sampling and Residue Details for Intermediates here, such as People involved in Sample collection, analysis & review, when were the sampling activities completed, and residue obtained at different Sampling Locations.",
  bioburden = "Users will be asked to record Sampling and Residue Details for Bioburden here, such as People involved in Sample collection, analysis & review, when were the sampling activities completed, and residue obtained at different Sampling Locations.",
  cleaningAgent = "Users will be asked to record Sampling and Residue Details for Cleaning Agents and other similar contaminants here, such as People involved in Sample collection, analysis & review, when were the sampling activities completed, and residue obtained at different Sampling Locations.",
  microbial = "Users will be asked to record Sampling and Residue Details for Microbials here, such as People involved in Sample collection, analysis & review, when were the sampling activities completed, and residue obtained at different Sampling Locations.",
  endotoxin = "Users will be asked to record Sampling and Residue Details for Endotoxins here, such as People involved in Sample collection, analysis & review, when were the sampling activities completed, and residue obtained at different Sampling Locations.",
  rinseClarity = "Users will be asked to record Rinse Clarity Verification Details here such as people involved in Rinse Clarity Verification and when Rinse Clarity Verification was completed.",
  odour = "Users will be asked to record Odour Verification Details here such as people involved in Odour Verification and when Odour Verification was completed.",
  TOC = "Users will be asked to record Sampling Details for the TOC Tests here, such as People involved in Sample collection, analysis & review, when were the sampling activities completed.",
  atomicAdsorption = "Users will be asked to users will be asked to record Sampling Details for the Atomic Absorption Tests here, such as People involved in Sample collection, analysis & review, when were the sampling activities completed.",
  pH = "Users will be asked to record Sampling Details for the pH Tests here, such as People involved in Sample collection, analysis & review, when were the sampling activities completed.",
  conductivity = "Users will be asked to record Sampling Details for the Conductivity Tests here, such as People involved in Sample collection, analysis & review, when were the sampling activities completed.",
  nitrosamines = "Users will be asked to record Sampling and Residue Details for Nitrosamines and other applicable genotoxic impurities here, such as People involved in Sample collection, analysis & review, when were the sampling activities completed, and residue obtained at different Sampling Locations.",
  bacterial = "",
  fungal = ""
}

export enum HeadingForStaticStages {
  visual = "Equipment should be visually clean.",
  rinseClarity = "Rinse samples should be visually clear.",
  odour = "Equipment should be odour-free."
}

export enum DescriptionForStaticStages {
  visual = "Equipment will be inspected by trained personnel for any visually detectable residue on its surface. Equipment is considered to be visually clean if no residue is found.",
  rinseClarity = "Rinse samples collected from Equipment will be inspected by trained personnel for visual clarity.",
  odour = "Products that tend to leave an odour on an Equipment after cleaning will be verified for residual odour by trained personnel."
}

export enum CriteriaDescriptionForStages {
  visual = "Equipment should be visually clean.",
  rinseClarity = "Rinse samples should be visually clear.",
  odour = "Equipment should be odour-free."
}

export enum InspectionStageApiPath {
  batchDetails = "",
  cleaningDetails = "",
  visual = "",
  actives = "actives",
  intermediates = "intermediates",
  bioburden = "bioburden",
  cleaningAgent = "ca",
  microbial = "microbial",
  endotoxin = "endotoxin",
  rinseClarity = "",
  odour = "",
  TOC = "additional/TOC",
  atomicAdsorption = "additional/AA",
  pH = "additional/pH",
  conductivity = "additional/Conductivity",
  nitrosamines = "nitrosamines"
}

export enum PolicyForActivesAndIntermediates {
  CUSTOM = "custom",
  EQUIPMENT_WISE = "equipmentWise",
  PRODUCT_WISE = "productWise",
  POLICY_BASED = "policyBased"
}

export enum PolicyForAPI {
  CUSTOM = "custom",
  PRODUCT_WISE = "productWise",
  POLICY_BASED = "policyBased"
}

export enum PolicyForInspections {
  CUSTOM = "custom",
  POLICY_BASED = "policyBased"
}

export enum PolicyForCleaningAgentVerification {
  CUSTOM = "custom",
  CLEANING_AGENT_SAL = "cleaningAgentSal",
  POLICY_BASED = "policyBased"
}

export enum PolicyLabel {
  custom = "Custom",
  equipmentWise = "Lowest SAL on the Equipment",
  productWise = "Lowest SAL amongst the selected Production Group for a Material",
  policyBased = "Based on Policy",
  cleaningAgentSal = "Cleaning Agent's SAL Limits"
}

export enum PolicyDescription {
  equipmentWise = "For each Equipment, the Acceptance Criteria would be the lowest surface area limit amongst all possible Production combinations on that Equipment",
  productWise = "For each Equipment, the Acceptance Criteria for each Material would be the lowest surface area limit from all selected Productions of the Material in the Protocol.",
  cleaningAgentSal = "Cleaning Agent's lowest MAC limit amongst all possible next Productions on a selected Equipment"
}

export enum ProductionStatusTypes {
  Verified = "verified",
  New = "new"
}

export enum DomainsWithMessage {
  OnDemandProtocol = "OnDemandProtocol",
  OnDemandStudy = "OnDemandStudy",
  OnDemandInspection = "OnDemandInspection",
  Nitrosamine = "Nitrosamine",
  FacilityCleaningEvaluation = "FacilityCleaningEvaluation",
  Production = "Production",
  OfflineStudy = "OfflineStudy",
  ElectronicRecordApproval = "ElectronicRecordApproval"
}

export enum CustomReportType {
  WorstCaseRanking = "WorstCaseRanking",
  EquipmentLimit = "EquipmentLimit",
  ProductionLimit = "ProductionLimit",
  CleaningAgentLimit = "CleaningAgentLimit",
  AnnualSummaryReport = "AnnualSummaryReport",
  MasterDataReport = "MasterDataReport"
}

export enum CustomReportTypeTitle {
  WorstCaseRanking = "Worst Case Ranking",
  EquipmentLimit = "Equipment Limits",
  ProductionLimit = "Production Limits",
  CleaningAgentLimit = "Cleaning Agent Limits",
  AnnualSummaryReport = "Annual Summary Reports and Legacy Reports",
  MasterDataReport = "Master Data Snapshot"
}

export enum CustomReportTypeReportConfigFieldType {
  WorstCaseRanking = "criteria",
  EquipmentLimit = "equipment",
  ProductionLimit = "production",
  CleaningAgentLimit = "cleaningAgent"
}

export enum CustomReportTypeReportConfigTitle {
  WorstCaseRanking = "Criteria",
  EquipmentLimit = "Equipment",
  ProductionLimit = "Previous Production",
  CleaningAgentLimit = "Cleaning Agent"
}

export enum CustomReportTypeReportConfigDataIndex {
  WorstCaseRanking = "criteriaId",
  EquipmentLimit = "equipmentId",
  ProductionLimit = "productionId",
  CleaningAgentLimit = "cleaningAgentId"
}

export enum CustomReportTypeDescription {
  ProductionLimit = "View how limits are calculated for Productions on the basis of various combinations of Productions passing through their Equipment Trains",
  EquipmentLimit = "View how limits are calculated for Equipment on the basis of various combinations of Productions passing through them",
  CleaningAgentLimit = "View how limits are calculated for Cleaning Agents on the basis of various combinations of Productions passing through Equipment cleaned by them",
  WorstCaseRanking = "View how Worst-case Rankings are determined on the basis of different selection rules and scores",
  AnnualSummaryReport = "View summary reports of the Validation Status of your Facility and legacy reports from previous versions of CLEEN",
  MasterDataReport = "View different version of Master Data applicable for the various Change Assessments done over time"
}

export enum CustomReportTypeColor {
  ProductionLimit = "#F5222D",
  EquipmentLimit = "#2F54EB",
  CleaningAgentLimit = "#9254DE",
  WorstCaseRanking = "#13C2C2",
  AnnualSummaryReport = "#FA8C16",
  MasterDataReport = "#52C41A"
}

export enum CustomReportInputFieldType {
  DropDown = "DropDown",
  DateTime = "DateTime",
  Text = "Text",
  Radio = "Radio"
}

export enum CustomReportFieldType {
  production = "production",
  equipment = "equipment",
  cleaningAgent = "cleaningAgent",
  criteria = "criteria",
  masterData = "masterData"
}

export enum IntakeType {
  AI = "AI",
  ADE = "ADE"
}

export enum IntakeTypeLabel {
  AI = "Acceptable Intake",
  ADE = "Acceptable Daily Exposure"
}

export enum CustomSectionPageType {
  DESCRIPTION = "DESCRIPTION",
  EQUIPMENT_ENTITIES = "EQUIPMENT_ENTITIES",
  VERIFICATIONS = "VERIFICATIONS",
  ACCEPTANCE_CRITERIA = "ACCEPTANCE_CRITERIA",
  SAMPLING_PROFILE = "SAMPLING_PROFILE",
  SAMPLE_LIMIT = "SAMPLE_LIMIT",
  STUDY_DETAILS = "STUDY_DETAILS"
}

export enum CustomSectionEntityType {
  ON_DEMAND_PROTOCOL = "ON_DEMAND_PROTOCOL",
  ON_DEMAND_STUDY = "ON_DEMAND_STUDY"
}

export enum HistoricalStudyResidueType {
  ACTIVE = "Active",
  CLEANINGAGENT = "Cleaning Agent",
  MICROBIAL = "Microbial",
  INTERMEDIATE = "Intermediate",
  NITROSAMINE = "Nitrosamine"
}

export enum MicrobialAMType {
  Bioburden = "bioburden",
  Endotoxin = "endotoxin",
  Fungal = "fungal",
  Bacterial = "bacterial"
}

export enum MasterDataReportEntitySectionTitle {
  Actives = "Actives",
  Equipment = "Equipment",
  SamplesAndSamplingLocations = "Samples and Sampling locations",
  SamplingLocationRiskAssessment = "Sampling location risk assessment",
  EquipmentGroups = "Equipment groups",
  AnalyticalMethods = "Analytical methods",
  MicrobialAnalyticalMethods = "Microbial Analytical methods",
  Productions = "Productions",
  ProductionEquipmentMatrix = "Production-Equipment matrix",
  CleaningAgents = "Cleaning agents",
  CleaningProcedures = "Cleaning procedures",
  CleaningProcedureEquipmentMapping = "Cleaning procedure - Equipment mapping",
  ProductionAnalyticalMethodMapping = "Production - Analytical method mapping",
  Nitrosamines = "Nitrosamines",
  ProductionNitrosamineMapping = "Production - nitrosamine mapping",
  Intermediates = "Intermediates",
  Products = "Products",
  ProductionEquipmentProperties = "Production-equipment property"
}

export enum MasterDataReportEntityType {
  Actives = "Actives",
  Equipment = "Equipment",
  SamplesAndSamplingLocations = "SamplesAndSamplingLocations",
  SamplingLocationRiskAssessment = "SamplingLocationRiskAssessment",
  EquipmentGroups = "EquipmentGroups",
  AnalyticalMethods = "AnalyticalMethods",
  MicrobialAnalyticalMethods = "MicrobialAnalyticalMethods",
  Productions = "Productions",
  ProductionEquipmentMatrix = "ProductionEquipmentMatrix",
  CleaningAgents = "CleaningAgents",
  CleaningProcedures = "CleaningProcedures",
  CleaningProcedureEquipmentMapping = "CleaningProcedureEquipmentMapping",
  ProductionAnalyticalMethodMapping = "ProductionAnalyticalMethodMapping",
  Nitrosamines = "Nitrosamines",
  ProductionNitrosamineMapping = "ProductionNitrosamineMapping",
  Intermediates = "Intermediates",
  Products = "Products",
  ProductionEquipmentProperties = "ProductionEquipmentProperties"
}

export enum SampleTypeVisual {
  Detergent = "Cleaning Agent",
  Chemical = "Chemical",
  Additional = "Additional",
  Endotoxin = "Endotoxin",
  Bioburden = "Bioburden",
  Microbial = "Microbial",
  Nitrosamine = "Nitrosamine",
  TOC = "TOC",
  pH = "pH",
  Conductivity = "Conductivity",
  AA = "AA",
  Odour = "Odour",
  RC = "RC"
}

export enum ReferenceDocumentEntityType {
  Equipment = "Equipment",
  AnalyticalMethod = "AnalyticalMethod",
  Actives = "Actives",
  OnDemandInspection = "OnDemandInspection",
  OnDemandProtocol = "OnDemandProtocol",
  HistoricalStudy = "HistoricalStudy",
  EquipmentPart = "EquipmentPart"
}

export enum FileUploadType {
  PDF = "application/pdf",
  PLAIN_TXT = "text/plain; charset=utf-8",
  RTF = "application/rtf",
  DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  JPEG = "image/jpeg",
  PNG = "image/png",
  DOC = "application/msword",
  TXT = "text/plain"
}

export enum VerificationFieldTypeLabel {
  label = "label",
  fixed = "fixed",
  selected = "selected"
}

export enum VerificationFieldLabel {
  sampleTakenBy = "Sample Taken By",
  sampleTakenOn = "Sample Taken On",
  sampleTakenAt = "Sample Taken At",
  analyticalTestNumber = "Analytical Test Number",
  observationBelowDetectionLimit = "Observed Below Detection Limit",
  notDetected = "Not Detected",
  observedResidue = "Observed Residue",
  sampleMeasuredBy = "Sample Measured By",
  sampleMeasuredOn = "Sample Measured On",
  sampleMeasuredAt = "Sample Measured At",
  sampleReviewedBy = "Sample Reviewed By",
  sampleReviewedAt = "Sample Reviewed At",
  sampleReviewedOn = "Sample Reviewed On",
  additionalInformation = "Additional Information",
  selectedAM = "Select Analytical Method",
  residueForBioburden = "Residue for Bioburden",
  residueForBacterial = "Residue for Bacterial",
  residueForFungal = "Residue for Fungal",
  residueForEndotoxin = "Residue for Endotoxin",
  isAbsent = "Is Absent",
  adjustedObservedResidue = "Adjusted Observed Residue",
  batchNumber = "Batch Number",
  manufacturingCompletedAt = "Manufacturing Completed At",
  cleanedBy = "Cleaned By",
  cleaningStartedAt = "Cleaning Started At",
  cleaningCompletedAt = "Cleaning Completed At",
  inspectionDoneBy = "Inspection Done By",
  inspectionDoneOn = "Inspection Done On",
  visuallyClean = "Visually Clean",
  selectFallingLocations = "Select Failing Locations",
  verificationDoneBy = "Verification Done By",
  verificationDoneOn = "Verification Done On",
  odourFree = "Location is odour free",
  residueOption = "Residue Option"
}

export enum SSOProvider {
  azure = "azure",
  okta = "okta"
}

export enum OfflineStudyType {
  Validation = "validation",
  Verification = "verification",
  PeriodicVerification = "periodicVerification",
  NewProductIntroduction = "newProductIntroduction",
  ProductChangeover = "productChangeover",
  BatchChangeover = "batchChangeover",
  OnDemandStudy = "onDemandStudy"
}

export enum OfflineStudyTypeVisual {
  validation = "Validation",
  verification = "Verification",
  periodicVerification = "Periodic Verification",
  newProductIntroduction = "New Product Introduction",
  productChangeover = "Product Changeover",
  batchChangeover = "Batch Changeover",
  onDemandStudy = "Study"
}

export enum DocumentType {
  OnDmenadProtocol = "Protocol",
  OnDemandStudy = "Study",
  OnDemandInspection = "Inspection",
  LWCR = "Limits And Worst Case Report",
  CAR = "Change Assessment Report"
}

export enum EquipmentGroupTables {
  PRODUCTS = "products",
  EQUIPMENTS = "equipments"
}

export enum CacheKeys {
  Evaluation = "evaluation",
  Facilities = "facilities",
  Policies = "policies",
  DefaultUnits = "defaultunits",
  ProductionToEquipmentMapping = "productiontoequipmentmapping",
  Equipments = "equipments",
  MappedEquipmentRaw = "mappedeqraw",
  WorstDetergentDirectAreaAndVolumePerEquipment = "worstdetergentdirectareaandvolume",
  CleaningAgents = "cleaningagents",
  CleaningAgentDetails = "cleaningagentdetails",
  WorstDetergentDirectSwabAreaPerEquipment = "worstdirectswabarea",
  EquipmentGroupDetailsMap = "equipmentgroupdetailsmap",
  AnalyticalMethods = "analyticalmethods",
  Productions = "productions"
}

export enum RunFieldTypes {
  SingleLineText = "SINGLE_LINE_TEXT",
  DateTime = "DATE_TIME",
  MultiLineText = "MULTI_LINE_TEXT",
  YesOrNo = "YES_OR_NO",
  Date = "DATE",
  Number = "NUMBER",
  UsersList = "USERS_LIST",
  LocationList = "LOCATION_LIST",
  SingleSelect = "SINGLE_SELECT"
}

export enum RunFieldTypesVisual {
  "SINGLE_LINE_TEXT" = "Single-line text",
  "DATE_TIME" = "Date-Time",
  "MULTI_LINE_TEXT" = "Multi-line text",
  "YES_OR_NO" = "Yes/No",
  "DATE" = "Date",
  "NUMBER" = "Number",
  "USERS_LIST" = "Users List",
  "LOCATION_LIST" = "Location List",
  "SINGLE_SELECT" = "Single Select"
}
